import React, { useEffect, useState } from "react";
import * as constantVar from "../../Constant";
import CompassLogo from "../../assets/logo/Compass_White.png";
import StoryImg1 from "../../assets/images/StoryImg1.png";
import StoryImg2 from "../../assets/images/StoryImg2.png";
import HighlightGalleryImg from "../../assets/images/AttentiveImg.png";
import paperBG from "../../assets/images/paperBG.png";
import RoseGoldLinearCompass from "../../assets/logo/Compass_RoseGold_Linear.png";
import "./Home.css";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import JapanTravelImg from "../../assets/images/TripGuideImg.png";
import PackageImg from "../../assets/images/OFFGUNFUNTRIP.png";

export default function Home() {
  const [article_list, set_article_list] = useState([]);
  const [home_package, set_home_package] = useState({});
  const [home_package_img, set_home_package_img] = useState("");
  const [home_package_detail, set_home_package_detail] = useState("");
  const [home_package_contact, set_home_package_contact] = useState("");
  async function getArticles() {
    let response = await fetch(
      constantVar.backend_url + "api/get-articles?page=1"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
    set_article_list(response);
  }
  async function getHomePackage() {
    let response = await fetch(constantVar.backend_url + "api/get-home-package")
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log("result error", error);
        }
      );
    set_home_package(response);
  }
  function generateNewsSlideItem() {
    return article_list.data.map((article) => (
      <Carousel.Item>
        <Link to={"/blog-detail/" + article.id}>
          <img
            className="d-block w-100"
            style={{ objectPosition: "top" }}
            src={article.cover_img_path}
            alt="article.title"
          />
        </Link>
      </Carousel.Item>
    ));
  }

  const isVideo = (url) => {
    return /\.(mp4|mov)$/i.test(url);
  };

  useEffect(() => {
    getArticles();
    getHomePackage();
  }, []);
  return (
    <div className="app-background-container ">
      <div className="home-banner">
        <div className="app-content-container">
          <img className="compass-img" src={CompassLogo} alt="" />
          <h1 className="home-title">Where magic happens as you wish</h1>
        </div>
      </div>
      <div className="home-linear-background">
        <div className="package-tour">
          {home_package.display_type === "Youtube Link" ? (
              <iframe
                  width="100%"
                  // height="315"
                  src={home_package.youtube_link}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="responsive-iframe"
              ></iframe>
          ) : home_package.display_type === 'Video Upload' && home_package.video_path ? (
              <video src={home_package.video_path} loop
                     muted controls width="100%" height="auto" >
                Your browser does not support the video tag.
              </video>
          ) : home_package.display_type === 'Image Upload' ?(
              <img src={home_package.img_path} alt="Package" />
          ) : <></>}

          <div className="package-tour-btn">
            <a
                href={home_package.detail_file}
                target="_blank"
                rel="noreferrer"
            >
              Detail
            </a>
            <a
                href={home_package.contact_link}
                target="_blank"
                rel="noreferrer"
            >
              Reservation
            </a>
          </div>
        </div>
        <div className="story-image-container">
          <img
            className="story-big-image"
            src={StoryImg1}
            alt="story-img-1"
          ></img>
          <img
            className="story-small-image"
            src={StoryImg2}
            alt="story-img-2"
          ></img>

          <p className="story-title-quentin">Signature</p>
          <p className="story-title">Travel Experience</p>
        </div>
        <p className="story-description">
          ออกแบบการเดินทางในรูปแบบที่คุณชื่นชอบ
          วาดเส้นทางการเดินทางตามภาพที่คุณฝัน
          เพื่อเป้าหมายของการเดินทางที่ดีที่สุดในแบบที่คุณต้องการ
        </p>
        <div className="highlight-gallery-container">
          <div className="highlight-gallery-content-container">
            <div className="highlight-gallery-image-container">
              <img
                className="highlight-gallery-img"
                src={HighlightGalleryImg}
                alt="highlight gallery img"
              />
              <img
                className="highlight-gallery-compass"
                src={RoseGoldLinearCompass}
                alt="omakase voyage compass logo"
              />
            </div>
            <div className="gallery-img-content">
              <h1>
                <span className="gallery-title-quentin">
                  &nbsp;&nbsp;Indicated by{" "}
                </span>
                <br />
                Attentive <br />
                &nbsp;&nbsp;Trip Master
              </h1>
              <p>
                ดูแลอย่างใส่ใจในทุกขั้นตอนด้วยผู้ที่มีความเชี่ยวชาญเกี่ยวกับการเดินทาง
                ให้คำแนะนำได้ในทุกๆด้านไม่ว่าจะเป็นเส้นทางการเดินทาง
                จุดหมายปลายทาง การประสานงานทางธุรกิจ
                รวมไปถึงประสบการณ์การลิ้มรสอาหารที่แสนพิเศษ
              </p>
            </div>
          </div>
          <img className="paperBG" src={paperBG} alt="paper background" />
        </div>
        <div className="news-activity-container">
          <div className="news-activity-content">
            <div className="news-activity-title">
              <Link to={"/blog"}>
                <h1>
                  News
                  <br />
                  Update
                </h1>
                {/* <h1 className="news-activity-title-quentin">update</h1> */}
                <h2>&</h2>
              </Link>
            </div>
            <div className="news-activity-description">
              <p>
                ข้อมูลสำคัญเกี่ยวกับการเดินทางและข่าวสารที่เป็นประโยชน์
                <br />
                ต่อการเดินทางในประเทศญี่ปุ่นที่ไม่ควรพลาด
              </p>
            </div>
          </div>
          <div className="news-activity-carousel">
            <Carousel>
              {article_list.data &&
                article_list.data.length > 0 &&
                generateNewsSlideItem()}
            </Carousel>
          </div>
        </div>
        <div className="japan-travel-container">
          <div className="japan-travel-content-container">
            <h1>
              Japan
              <br />
              Travel
            </h1>
            <h2>Tips</h2>
            <button disabled>Coming Soon</button>
          </div>
          <div className="japan-travel-img">
            <img src={JapanTravelImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
